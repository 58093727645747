<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="errorState"
  [image]="imgSrc"
  [anotherActionButton]="anotherButton">
  <ng-container title>Дорогие друзья!</ng-container>
  <ng-container description>
    <p class="text">К сожалению, цифровые рабочие тетради «Просвещения» больше недоступны пользователям МЭШ. <br /><br />
      Если вам понравились рабочие тетради и вы хотели бы продолжить ими пользоваться,
      свяжитесь с нами по адресу:
      <a type="email" href="mailto:hw@lecta.ru" style="color:#0B5DD8">hw&#64;lecta.ru</a>. <br /><br />
      С уважением, ваше «Просвещение»</p>
  </ng-container>
</app-error-page-layout>
